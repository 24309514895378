
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  company: string;
  payment: {
    icon: string;
    ccnumber: string;
    label: string;
  };
  date: string;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    AddCustomerModal,
  },
  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Customer Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "Payment Method",
        key: "paymentMethod",
        sortingField: "payment.label",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "date",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const customers = ref<Array<ICustomer>>([
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        email: "e.smith@kpmg.com.au",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Dec 2020, 8:43 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Melody Macy",
        email: "melody@altbox.com",
        company: "Google",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Dec 2020, 10:12 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Max Smith",
        email: "max@kt.com",
        company: "Bistro Union",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "12 Nov 2020, 2:01 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Sean Bean",
        email: "sean@dellito.com",
        company: "Astro Limited",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "21 Oct 2020, 5:54 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Brian Cox",
        email: "brian@exchange.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "19 Oct 2020, 7:32 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Mikaela Collins",
        email: "mikaela@pexcom.com",
        company: "Keenthemes",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "23 Sep 2020, 12:37 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Francis Mitcham",
        email: "f.mitcham@kpmg.com.au",
        company: "Paypal",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "11 Sep 2020, 3:15 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Olivia Wild",
        email: "olivia@corpmail.com",
        company: "-",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "03 Sep 2020, 1:08 am",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Neil Owen",
        email: "owen.neil@gmail.com",
        company: "Paramount",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "01 Sep 2020, 4:58 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Dan Wilson",
        email: "dam@consilting.com",
        company: "Trinity Studio",
        payment: {
          icon: "media/svg/card-logos/visa.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "visa",
        },
        date: "18 Aug 2020, 3:34 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Bold",
        email: "emma@intenso.com",
        company: "B&T Legal Services",
        payment: {
          icon: "media/svg/card-logos/mastercard.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "mastercard",
        },
        date: "14 Aug 2020, 1:21 pm",
      },
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Ana Crown",
        email: "ana.cf@limtel.com",
        company: "Paysafe Security",
        payment: {
          icon: "media/svg/card-logos/american-express.svg",
          ccnumber:
            "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
          label: "american_express",
        },
        date: "11 Aug 2020, 5:13 pm",
      },
    ]);
    const initCustomers = ref<Array<ICustomer>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      initCustomers.value.splice(0, customers.value.length, ...customers.value);

      setCurrentPageBreadcrumbs("Customers Listing", ["Apps", "Customers"]);
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      customers.value.splice(0, customers.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<ICustomer> = [];
        for (let j = 0; j < customers.value.length; j++) {
          if (searchingFunc(customers.value[j], search.value)) {
            results.push(customers.value[j]);
          }
        }
        customers.value.splice(0, customers.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      customers,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
    };
  },
});
